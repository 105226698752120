import { useState } from "react";
import Popup from "./Components/Popup"; 
import ContactForm from "./Components/SiteVisitForm";
import phone from "../assets/logos/calling.png"
import whatsapp from "../assets/logos/whatsappp.png"
import footerlogo from "../assets/logos/footer-logo.png"
import train from "../assets/logos/train-icon.png"

const Footer = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const togglePrivacyPolicyModal = () => {
    setShowPrivacyPolicyModal(!showPrivacyPolicyModal);
    if (!showPrivacyPolicyModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleDisclaimerModal = () => {
    setShowDisclaimerModal(!showDisclaimerModal);
    if (!showDisclaimerModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false); 

  const handleButtonClick = () => {
      setIsPopupVisible(true); 
  };

  const handleClosePopup = () => {
      setIsPopupVisible(false); 
  };

  return (
    <footer>
      {showPrivacyPolicyModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-7xl overflow-y-auto rounded-lg bg-white p-8 relative">
            <button
              onClick={togglePrivacyPolicyModal}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <svg
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="pb-4 text-xl font-bold">Privacy Policy:</h2>
            <p className="pb-4">
            This Privacy Policy governs the style within which Aryav Contructions and its subsidiaries, partners, agents and affiliates collect, use, maintain and disclose info collected from users of our web site and microsite.

            Personal identification information:
            We could collect personal identification info from Users in an exceedingly form of ways that, including, however not restricted to, once Users visit our website, purchase the account, fill out a form, and in reference to different activities, services, options or resources we have a tendency to build accessible on our website.
            
            Users is also asked for, as acceptable, name, email address, mailing address, phone number.
            
            Users may, however, visit our Site anonymously.
            
            Users will forever refuse to provide personal identification info, except that it’s going to stop them from partaking in sure website connected activities.
            
            Non-personal identification information:
            We could collect non-personal identification info regarding Users whenever they move with our website.
            Non-personal identification info could embrace the browser name, the sort of laptop and technical info regarding Users means that the sort of association to our website, such as the operating system and also the net service suppliers used and different similar info.
            
            Web browser cookies:
            Our website could use “cookies” to boost User expertise.
            
            User’s applications program me places cookies on their disc drive for record-keeping functions and generally to trace info regarding them.
            
            User may choose to set their web browser to refuse cookies or to alert the Users when cookies are being sent.
            
            If they are doing therefore, note that some elements of the positioning might not operate properly.
            
            This is a standard operating procedure that is used across the internet.
            
            How we use collected information:
            
            Aryav Contructions may collect and use User’s personal information for the following purposes:
            
            To improve customer service:
            
            Information provided by Users helps America reply to the client service requests and support desires, additional expeditiously.
            
            To personalize User experience:
            We could use info within the mixture to know however our Users as a gaggle use the services and resources provided on our website.
            
            To improve our Site:
            We could use feedback provided by the User/s to enhance our merchandise and services.
            
            To run a promotion, contest, survey or different website feature.
            
            To send the User/s info they united to receive regarding topics of interest to them.
            
            To send periodic emails.
            We could use the e-mail address to reply to the inquiries, questions, and/or different requests of User’s.
            
            If User’s conceive to favor to be a part of our list, then the User’s will receive emails about company news, updates, related product or service information, etc.
            
            If at any time the User’s would really like to unsubscribe from receiving future emails, they’ll do therefore by contacting us.
            
            How we protect User’s information:
            We adopt acceptable knowledge assortment, storage and process practices and security measures to safeguard against unauthorized access, alteration, revelation or destruction of User’s personal info and knowledge keep on our website.
            
            As with data security, there are limits to its effectiveness and we indemnify ourselves in the event of an attack that is difficult to defend against.
            
            We conjointly can do our greatest to retrieve any knowledge that’s lost as per accessible resources.
            
            Sharing personal information of Users:
            We don’t sell, trade, or rent User’s personal identification info to others.
            
            We could share generic collective demographic info not connected to any personal identification info relating to User’s with our subsidiaries, our business partners, trusted affiliates and advertisers for the purposes outlined above.
            
            Changes to this privacy policy:
            Aryav Constructions shall update this privacy policy at its sole discretion.
            
            Users square measure suggested to examine this page for any changes within the privacy policy and to remain conversant regarding however the private info of the Users is protected.
            
            The User’s herewith acknowledge and agree that it’s their responsibility to review this privacy policy sporadically and become awake to modifications.
            
            Your acceptance of these terms:
            By victimisation this website, the Users signify their acceptance of this policy as may be modified from time to time.
            
            The Users square measure suggested to not access this website if they are doing not conform to our privacy policy.
            
            The higher than mentioned privacy policy shall be applicable for the information the knowledge the knowledge and data collected by our decision centers in addition.
            
            </p>
          </div>
        </div>
      )}

      {showDisclaimerModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-4xl overflow-y-auto rounded-lg bg-white p-8 relative">
            <button
              onClick={toggleDisclaimerModal}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <svg
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="mb-4 text-xl font-bold">Disclaimer:</h2>
            <p className="mb-4">
              The content of this website is for general information and guidance purposes only.
              By using or accessing the website you agree with the Disclaimer without any qualification or limitation.
              No information given on this website creates a warranty or expands the scope of any warranty that cannot be disclaimed
              under the applicable laws.
              The contents of this website are meant to provide information to the readers of this website about Aryav Constructions.
              It does not constitute part of an offer or contract. While enough care is taken by Aryav Constructions to ensure that information on
              the website is up to date and correct, readers are requested to make their independent enquiry before relying upon the information
              provided. Aryav Constructions reserves the right to add, alter or delete any material from the website at any time and may, at any time,
              revise these terms, change Design & Specifications, without prior notice. Computer generated images, walkthroughs and render images are
              the artist 2019 impression and are an indicative of the actual designs.
              In no event will Aryav Constructions be liable for any loss or damage including without any limitation, indirect or
              consequential loss or damage in connection with the use of information on this website.
              You are, therefore, required to verify all the details, including area, amenities, services, terms of sales, payments and
              other relevant terms independently with the sales team / company prior to concluding any decision.
            </p>
          </div>
        </div>
      )}
      <div className="md:hidden">
      <div className="mt-[-7.25rem] mb-3 text-center md:w-full md:flex md:gap-4 md:justify-center">
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
        Connected Living on
        </h1>
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
        Kodigehalli Main Road, Hoodi
        </h1>
      </div>
      <div className="flex items-center py-5 justify-center space-x-4">
    <img 
        src={train}
        alt="train-icon"
        className="w-8 h-8 object-cover"
    />
    <div className="flex flex-col gap-[2px]">
        <p className="text-xs text-left text-black">Hoodi Railway Station - <span className="font-semibold">2 Km</span></p>
        <p className="text-xs text-left text-black">Hoodi Metro Station - <span className="font-semibold">4 Km</span></p>
    </div>
        </div>
        </div>
      <div className="px-8 md:px-28 md:pb-20 md:hidden">
      <div className="flex flex-col pt-4 pb-10 md:flex-row md:pt-12">
          <div className="w-full md:w-1/2">

      <iframe
      title="Aryav Windfields"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.002268019494!2d77.7258033!3d13.0037639!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110026f23b95%3A0xdc60cd47f3da042c!2sAryav%20Windfields!5e0!3m2!1sen!2sin!4v1730283666722!5m2!1sen!2sin" 
    className="h-[12rem] w-full md:h-[465px] md:w-[570px]"
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
    </div>
      </div>
      </div>
      <div className="px-8 bg-black pt-6 pb-4 md:flex md:flex-col md:items-center">
        <p className="text-2xl text-white md:text-3xl md:mt-12">Get In Touch</p>
      <div className="md:w-1/2 md:py-2">
      <ContactForm/>
        </div>
        </div>
      <div className=" bg-black text-white md:flex md:flex-row md:items-center md:justify-around px-3 pt-4 md:w-full md:h-full flex flex-col pb-16 gap-3 md:pt-5 md:pb-24">

        <div className="flex items-center justify-center gap-2 md:flex-row md:gap-[12rem] md:ml-5">
        <img
        src={footerlogo}
        alt="footer-logo" 
        className="w-[65px] h-[23px] md:w-[9rem] md:h-[3rem]" 
    />
        <button
          className="text-[6.2px] md:text-lg text-white md:text-white" 
          onClick={toggleDisclaimerModal}
        >
          Disclaimer
        </button>
        <button
          className="text-[6.2px] md:text-lg text-white md:text-white"
          onClick={togglePrivacyPolicyModal}
        >
          Privacy Policy
          </button>
          <div className="text-center text-white md:text-white text-[6.2px] md:text-lg md:font-normal md:w-auto">
          RERA No. - PRM/KA/RERA/1251
          /446/PR/101024/007144
          </div>
      </div>
    </div>
    
    <div className="flex border-r gap-[1px] border-white !bg-[#313131] bg-opacity-[0.4] fixed bottom-0 z-[99999999] w-[100%] ">
    <div className="flex items-center justify-between bg-[#1A1A1A] p-3 md:p-3 text-black w-full">
        <div className="flex flex-grow md:ml-5">
            <a
                href="tel:+9632062089"
                className="flex-grow h-full flex items-center justify-center text-center"
            >
                <img
                    src={phone} 
                    alt="Call"
                    className="w-5 h-5 md:w-6 md:h-6" 
                />
            </a>
        </div>
    </div>

    <div className="flex items-center justify-between bg-[#1A1A1A] p-3 md:p-3 text-black w-full">
        <div className="flex flex-grow md:ml-5">
            <a
                href="https://wa.me/919632062089?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
                target="_blank"
                rel="noopener noreferrer"
                className="flex-grow h-full flex items-center justify-center text-center"
            >
                <img
                    src={whatsapp} 
                    alt="WhatsApp"
                    className="w-[25px] h-[25px] md:w-[28px] md:h-[28px]"
                />
            </a>
        </div>
    </div>

    <div className="flex items-center justify-between bg-[#1A1A1A] md:text-base px-3 py-2 md:p-3 text-[#E5D7B7] w-full">
        <div className="flex flex-grow md:ml-5">
            <button
                className="flex-grow text-md md:text-lg  relative h-full flex items-center justify-center"
                onClick={handleButtonClick}
            >
                Enquire
            </button>
        </div>
    </div>
</div>

      
      {isPopupVisible && (
        <Popup onClose={handleClosePopup}>
            <h2 className="text-lg text-left font-normal">Please Fill In The Form</h2>
            <ContactForm/>
        </Popup>
    )}
      
    </footer>
  );
};

export default Footer;
