import React, {useState } from "react";
import locationImage from "../assets/elevation/elevation1.png";
import locationImage2 from "../assets/elevation/Elevation2.png";
import locationImage3 from "../assets/elevation/Elevation3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSection.css";

const Banks = () => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
      setIsPopupVisible(true); 
  };

  const handleClosePopup = () => {
      setIsPopupVisible(false); 
  };


  return (
    <div className="px-0">
      <div className="flex flex-col gap-10">
      <div className="md:px-[10rem]">
      <Swiper
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      spaceBetween={30}
      slidesPerView={1}
      breakpoints={{
        768: {
          slidesPerView: 1, 
          spaceBetween: 30,
        },
      }}
      className="relative custom-swiper"
    >
        <SwiperSlide>
        <img
          src={locationImage}
          alt="Plot in Electronic City 1"
          className="object-cover w-full h-[180px] md:h-[780px] md:rounded-xl"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={locationImage2}
          alt="Plot in Electronic City 2"
          className="object-cover w-full h-[180px] md:h-[780px] md:rounded-xl"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={locationImage3}
          alt="Plot in Electronic City 3"
          className="object-cover w-full h-[180px] md:h-[780px] md:rounded-xl"
        />
      </SwiperSlide>
        </Swiper>
      </div>
    </div>
      
    </div>
  );
};

export default Banks;
