import React from "react";
import gym from "../assets/amenities/gym.png"
import swim from "../assets/amenities/swim.png"
import tt from "../assets/amenities/tt.png"
import trees from "../assets/amenities/trees.png"
import ride from "../assets/amenities/ride.png"
import train from "../assets/logos/train.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSection.css";

const Location = () => {
  return (
    <>
    <div className="px-4 py-7 md:px-28 md:pt-16 md:hidden">
      <div className="mt-6 mb-3 text-center md:w-full md:flex md:flex-col md:gap-4 md:justify-center">
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
          3800 Sq. ft of Clubhouse
        </h1>
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
          12+ Clubhouse & Outdoor Amenities
        </h1>
      </div>

      <p className="text-center text-xs px-4 font-normal leading-6 md:text-2xl mt-4 md:px-[22rem] md:mt-[4rem]">
        Table Tennis | Carrom Board | Party Hall | Gym | Swimming Pool | Children's Play Area | Badminton Court | Walking Path | Landscaped Open Areas
      </p>
    </div>
      <div className="px-4 py-7 md:px-28 md:pt-16 background-image">
      <div className="px-4 py-7 md:px-28 md:pt-12 hidden md:block">
      <div className="mt-6 mb-3 text-center md:w-full md:flex md:flex-col md:gap-4 md:justify-center">
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
          3800 Sq. ft of Clubhouse
        </h1>
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
          12+ Clubhouse & Outdoor Amenities
        </h1>
      </div>

      <p className="text-center text-xs px-4 font-normal leading-6 md:text-2xl mt-4 md:px-[4rem] md:mt-[4rem] md:mb-[0px]">
        Table Tennis | Carrom Board | Party Hall | Gym | Swimming Pool | Children's Play Area | Badminton Court | Walking Path | Landscaped Open Areas
      </p>
    </div>
    <div className="flex flex-col gap-10 pt-4 md:px-[3rem]">
    <div className="md:pt-10">
    <Swiper
    modules={[Pagination, Autoplay]}
    // pagination={{ clickable: true }}
    autoplay={{ delay: 2500, disableOnInteraction: false }}
    spaceBetween={10}
              slidesPerView={3.5}
              loop
    breakpoints={{
      768: {
        slidesPerView: 4.5, 
        spaceBetween: 15,
      },
    }}
    className="relative custom-swiper"
  >
      <SwiperSlide>
                <img
                src={swim}
        
        alt="Plot in Electronic City 1"
        className="object-cover w-[99px] h-[128px] md:h-[310px] md:!w-[238px] rounded-md md:rounded-[7px]"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
      src={tt}
        alt="Plot in Electronic City 2"
        className="object-cover w-[99px] h-[128px] md:h-[310px] md:!w-[238px] rounded-md md:rounded-[7px]"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        src={gym}
        alt="Plot in Electronic City 3"
        className="object-cover w-[99px] h-[128px] md:h-[310px] md:!w-[238px] rounded-md md:rounded-[7px]"
      />
              </SwiperSlide>
              <SwiperSlide>
              <img
                src={trees}
                alt="Plot in Electronic City 3"
                className="object-cover w-[99px] h-[128px] md:h-[310px] md:!w-[238px] rounded-md md:rounded-[7px]"
              />
              </SwiperSlide>
              <SwiperSlide>
              <img
                src={ride}
                alt="Plot in Electronic City 3"
                className="object-cover w-[99px] h-[128px] md:h-[310px] md:!w-[238px] rounded-md md:rounded-[7px]"
              />
            </SwiperSlide>
      </Swiper>
    </div>
        </div>
        <div className="hidden md:mt-28 md:flex md:flex-col md:gap-9">
      <div className=" mb-3 text-center md:w-full md:flex md:flex-col md:gap-4 md:justify-center">
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
        Connected Living on
        </h1>
        <h1 className="font-sans text-lg font-semibold text-black md:text-5xl">
        Kodigehalli Main Road, Hoodi
        </h1>
      </div>
      <div className="flex items-center py-5 justify-center space-x-4">
    <img 
        src={train}
        alt="train-icon"
        className="w-8 h-8 object-cover md:w-[2.5rem] md:h-[2.5rem]"
    />
    <div className="flex gap-[12px] md:flex">
        <p className="text-xl text-left text-black">Hoodi Railway Station - <span className="font-semibold">2 Km,</span></p>
        <p className="text-xl text-left text-black">Hoodi Metro Station - <span className="font-semibold">4 Km</span></p>
    </div>
        </div>
        </div>
  </div>
  
      </>
  );
};

export default Location;
