import React from "react";

const Popup = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center text-white bg-black bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="relative bg-black text-white w-[90%] md:w-[40%] p-6 rounded-lg shadow-lg">
        <button
          className="absolute top-0 right-2 text-[3rem] text-white"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
