import React from "react";
import logo from "../../src/assets/logos/Aryav-Windfields-navbarr-logo.webp";
import "./global.css";

const Navbar = () => {
    return (
        <div className="navbar-container">
            <nav className="navbar md:!py-6">
                <div className="navbar-logo-container">
                    <img
                        src={logo}
                        alt="Logo"
                        className="navbar-logo md:!h-16"
                    />
                </div>
            </nav>
            <div className="navbar-spacer"></div>
        </div>
    );
};

export default Navbar;
