import React from "react";


const SiteVisit = () => {
  return (

    // <div className="px-8 md:px-28 md:pb-20 ">
      <div className="flex flex-col pt-6 md:flex-row md:pt-0">
      <div className="w-full md:w-full md:mb-[4rem] hidden md:flex md:justify-center md:items-center">
          <iframe
          title="Aryav Windfields"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.002268019494!2d77.7258033!3d13.0037639!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110026f23b95%3A0xdc60cd47f3da042c!2sAryav%20Windfields!5e0!3m2!1sen!2sin!4v1730283666722!5m2!1sen!2sin" 
        className="h-[7rem] w-full md:h-[465px] md:w-[1161px] md:mb-[5rem]"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
      </div>
    // </div>
  );
};

export default SiteVisit;
