import React, { useState, useRef, useEffect } from "react";
import Loader from "./Loader";
import ThankYouPopup from "./ThankYouPopup";
import "../global.css"

function ContactForm() {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const [phoneError, setPhoneError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [formError, setFormError] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const contactFormRef = useRef(null);

  useEffect(() => {
    if (showPopup) {
      window.history.pushState(null, "", "/thankyou");
    } else {
      window.history.pushState(null, "", "/");
    }
  }, [showPopup]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "Phone") {
      validatePhoneNumber(value);
    }

    if ((name === "Name" || name === "Email" || name === "Phone") && formError) {
      setFormError("");
    }
  }

  function validatePhoneNumber(value) {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    const isValidLength = sanitizedValue.length === 10;
    const startsWithValidDigit = /^[6-9]/.test(sanitizedValue);

    if (!isValidLength || !startsWithValidDigit) {
      setPhoneError(
        "Phone number should have exactly 10 digits and start with 6, 7, 8, or 9."
      );
    } else {
      setPhoneError("");
    }
  }

  function validateForm() {
    if (!formData.Name || !formData.Email || !formData.Phone) {
      setFormError("Please fill in all required fields.");
      return false;
    }
    if (phoneError) {
      setFormError("Please enter a valid phone number.");
      return false;
    }
    return true;
  }

  function submitForm() {
    if (!validateForm()) {
      return;
    }
  
    setSubmitting(true);
  
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source") || "Organic";
    const utmMedium = urlParams.get("utm_medium") || "Organic";
    const utmCampaign = urlParams.get("utm_campaign") || "Organic";
  
    const formDatab = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDatab.append(key, value);
    });
  
    formDatab.append("UTM_Source", utmSource);
    formDatab.append("UTM_Medium", utmMedium);
    formDatab.append("UTM_Campaign", utmCampaign);
    formDatab.append("DateReceived", new Date());
  
    fetch(
      "https://script.google.com/macros/s/AKfycbyIVvsoiG-hnYvlPR7_jf_7lOZaGezqWLtj-MuN0C0rp9-t0vvEU45BkpJLb0YtoA4M/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
        if (data.toLowerCase().includes("success")) {
          startBufferTimer();
          setFormData({
            Name: "",
            Email: "",
            Phone: "",
            Message: "",
          });
          setPhoneError("");
          setShowPopup(true);
          triggerGoogleTag();
        } else {
          console.log("Error submitting the form");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
        contactFormRef.current.scrollIntoView({
          behavior: "smooth",
        });
      });
  }
  

  function startBufferTimer() {
    setCountdown(3);
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      setShowPopup(true);
    }, 3000);
  }

  function triggerGoogleTag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submit",
      event_time: new Date(),
    });
  }

  return (
    <div>
      <div className="rounded-lg bg-black md:py-4 md:pt-5">
        <div className="mx-auto overflow-hidden rounded-lg bg-black md:w-full">
          <form
            className="py-4"
            ref={contactFormRef}
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <input
              className=" bg-black py-2 pl-0 pr-2 text-sm text-white border-b-[0.5px] border-[#8D8D8D] md:py-6 md:pr-6 md:pl-0 text-left md:text-left w-full "
              placeholder="Name"
              name="Name"
              type="text"
              required
              value={formData.Name}
              onChange={handleInputChange}
            />

            <input
              className=" bg-black py-2 pl-0 pr-2 text-sm text-white border-b-[0.5px] border-[#8D8D8D] md:py-6 md:pr-6 md:pl-0 text-left w-full md:text-left "
              placeholder="Email"
              name="Email"
              type="text"
              value={formData.Email}
              onChange={handleInputChange}
              required
            />

            <input
              className=" bg-black py-2 pl-0 pr-2 text-sm text-white border-b-[0.5px] border-[#8D8D8D] md:py-6 md:pr-6 md:pl-0  text-left w-full md:text-left"
              placeholder="Mobile Number"
              name="Phone"
              type="text"
              value={formData.Phone}
              onChange={handleInputChange}
              required
            />

            <input
            className=" bg-black py-2 pl-0 pr-2 text-sm text-white border-b-[0.5px] border-[#8D8D8D] md:py-6 md:pr-6 md:pl-0 text-left w-full md:text-left"
              placeholder="Message"
              name="Message"
              type="text"
              value={formData.Message}
              onChange={handleInputChange}
            />

            <br />
            <input value={new Date()} name="DateReceived" hidden readOnly />

            {formError && (
              <p className="mt-3 text-center text-sm text-red-500">
                {formError}
              </p>
            )}
            <div className="flex justify-start mt-8">
              {submitting ? (
                <Loader />
              ) : (
                <button className="cursor-pointer text-xs md:text-sm w-[20%] md:w-[20%] bg-[#E5D7B7] p-1 md:p-3 text-black" type="submit">
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {showPopup && <ThankYouPopup onClose={() => setShowPopup(false)} />}
    </div>
  );
}

export default ContactForm;
